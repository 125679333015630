import { Col, Row, DatePicker, Button } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getListTransaction } from 'services/api/TransactionApi'
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from 'utils/constants'
import { formatPrice, handleConvertKeyStatus } from 'utils/ruleForm'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'
const dateFormat = 'DD/MM/YYYY'

const HistoryTransactionPage = () => {
  const [transactions, setTransactions] = useState<Array<any>>([])
  const { t } = useTranslation()

  const transactionType = {
    ALL: { index: 0, title: t('all_type'), type: undefined },
    [TRANSACTION_TYPE.TOPUP]: {
      index: 1,
      title: t('money_history'),
      type: TRANSACTION_TYPE.TOPUP,
    },
    [TRANSACTION_TYPE.CASH_OUT]: {
      index: 2,
      title: t('withdrawal_history'),
      type: TRANSACTION_TYPE.CASH_OUT,
    },
    [TRANSACTION_TYPE.PURCHASE_LEVEL]: {
      index: 3,
      title: t('upgrade_level_history'),
      type: TRANSACTION_TYPE.PURCHASE_LEVEL,
    },
  }

  const handleClickTab = async (tab: any) => {
    let tabs = document.getElementsByClassName('title-tabs')
    let current = document.getElementsByClassName('active')
    if (current[0]) {
      current[0].className = current[0].className.replace(' active', '')
    }
    tabs[tab.index].className = tabs[tab.index].className.concat(' active')

    const transactionData = (await getListTransaction({ type: tab.type })).data
    setTransactions(transactionData)
  }

  const getData = async () => { }

  useEffect(() => {
    handleClickTab(transactionType.ALL)
    // getData()
  }, [])

  const renderTransactionItem = (item: any) => {
    const transactionType =
      item.type == TRANSACTION_TYPE.TOPUP
        ? 'Nạp tiền'
        : item.type == TRANSACTION_TYPE.CASH_OUT
          ? 'Rút tiền'
          : 'Thanh toán VIP'

    return (
      <Styled.ItemWrapper>
        <Row justify="space-between">
          <Col style={{ fontSize: 16 }}>
            {t('code_transaction')}: <b>{item._id}</b>
          </Col>
          <Col style={{ fontSize: 16, color: '#999' }}>
            {moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}
          </Col>
        </Row>
        <Row justify="space-between">
          <Col style={{ fontSize: 16 }}>
            {t('value_transaction')}:{' '}
            <b
              style={{
                color: item.type == TRANSACTION_TYPE.TOPUP ? 'green' : 'red',
              }}
            >
              {formatPrice(item.amount)} Điểm
            </b>
          </Col>
          <Col style={{ fontSize: 16 }}>
            {t('type_transaction')}: <b>{transactionType}</b>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col style={{ fontSize: 16 }}>
            {transactionType} {t('from')}:{' '}
            {item?.meta?.user_bank?.bank_name ||
              item?.meta?.admin_topup_method?.bank_name}
          </Col>
          <Col style={{ fontSize: 16 }}>
            {t('Surplus')}: <b>{formatPrice(item.balance) || 0} Điểm</b>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col style={{ fontSize: 16 }}>
            {t('value_status')}: <b style={{
              color: item.status == "Pending"
                ? "blue"
                : item.status == "Success"
                  ? "green"
                  : "volcano"
            }}>{`${handleConvertKeyStatus(item.status)}`}</b>
          </Col>
        </Row>
      </Styled.ItemWrapper>
    )
  }

  return (
    <Styled.Container>
      <Styled.Title>{t('money_history')}</Styled.Title>
      <Row style={{ marginTop: '1rem' }} justify="space-around">
        <Styled.ColStyled xs={24} md={8}>
          <DatePicker format={dateFormat} />
        </Styled.ColStyled>
        <Styled.ColStyled xs={24} md={8}>
          <DatePicker format={dateFormat} />
        </Styled.ColStyled>
      </Row>
      <Row justify="center">
        <Button
          style={{
            margin: '16px 0 20px 0',
            alignSelf: 'center',
            borderColor: '',
          }}
        >
          {t('find')}
        </Button>
      </Row>
      <Styled.ContainerTabs>
        <Styled.HeaderTabs>
          {Object.values(transactionType).map((item, index) => (
            <Col
              key={index}
              span={24 / Object.keys(transactionType).length}
              className="title-tabs"
              onClick={() => {
                handleClickTab(item)
              }}
            >
              {item.title}
            </Col>
          ))}
        </Styled.HeaderTabs>
      </Styled.ContainerTabs>
      {transactions.length ? (
        <div style={{ padding: '0px 20px', marginTop: 20 }}>
          {transactions.map(item => renderTransactionItem(item))}
        </div>
      ) : (
        <Styled.Empty>{t('page_empty')}</Styled.Empty>
      )}
    </Styled.Container>
  )
}
export default HistoryTransactionPage
