import { message, notification } from 'antd'
import R from 'assets'
import { LightBulbIcon } from 'common/components/Icons'
import BlueIconComp from 'common/components/Icons/BlueIconComp'
import { ADMIN_ROUTER_PATH } from 'common/config'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'
import { requestAnOrder, sendOrder } from './api/ApiOrder'
import * as S from './styled'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'redux/store/store'
import { getUserAnalytic } from 'services/api/CommonApi'

import {
  requestLevelCategory,
  requestLevelDetail,
  requestLevelList,
} from '../home/Api/LevelApi'


const styleIcon = { fontSize: '35px', color: 'white' }

const BillOrder: React.FC = () => {
  const [data, setData] = useState<any>({})
  const location: any = useLocation()
  const { t } = useTranslation()
  const { userInfo } = useAppSelector(state => state.AuthReducer)
  const [dataLevel, setDataLevel] = useState<any>({})
  const [analyticDetail, setAnalyticDetail] = useState<any>()




  useEffect(() => {
    console.log('location.state: ', location.state)
    if (location.state.own_level) {
      getData(location.state.own_level)
    } else {
      getData(location.state.first_level)
    }
    getLevelList()
      ; (async () => {
        const analyticData = await getUserAnalytic()
        setAnalyticDetail(analyticData.data)
      })()
  }, [])

  const getLevelList = async () => {
    try {
      const res = await requestLevelList()

      const ownLevel = userInfo?.level

      let currentLevelData = res.data.find((item: any) => item.key == ownLevel)
      currentLevelData = currentLevelData ? currentLevelData : res.data[0]

      setDataLevel(currentLevelData)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }


  const getData = async (key: any) => {
    try {
      const res = await requestAnOrder(key)
      setData(res.data)
    } catch (error) {
      history.goBack()
      // message.error('Có lỗi xảy ra')
    }
  }

  const handleClickBtn = async () => {
    try {
      const res = await sendOrder(data?._id)
      if (res.data?.status === 'Success') {
        notification.success({
          message: t('order'),
          description: t('send_order_success'),
        })
        history.push(ADMIN_ROUTER_PATH.ORDER)
      }
    } catch (error) {
      // message.error('Không đủ số dư, vui lòng nạp tiền vào tài khoản')
    }
  }

  return (
    <S.WrapContainer>
      <S.BillContainer>
        {/* <S.Title>
          <BlueIconComp icon={<LightBulbIcon style={styleIcon} />} />
          {t('order_unit')}
        </S.Title> */}
        {/* <div style={{ marginTop: '1rem' }}>
          {t('time_reciver')}：
          {data?.created_at &&
            moment(data?.created_at).format('DD/MM/YYYY HH:mm:ss')}
        </div> */}
        <div className="info">
          {t('account_balance')}：{formatPrice(userInfo?.balance)} Điểm
          <span className="contents">
            {/* {t('commission')} {formatPrice((dataLevel?.commission_percent / 100) * dataLevel?.price)} $
             */}
            {t('commission')} {analyticDetail?.total_commission_today} Điểm
          </span>
        </div>
        {/* <b>{data?.meta?.level.commission_percent}%</b> */}
        <S.ImageBlock>
          <img
            alt="gif"
            src={process.env.REACT_APP_IMG_URL + data?.product?.image}
            crossOrigin="anonymous"
          />
        </S.ImageBlock>
        <div className="infoText">
          {data?.meta?.order_limit == 1 ? (
            <p className="centered-text">
              Chúc mừng bạn nhận được đơn hàng may mắn. Số điểm quy đổi là
              <span className="highlight"> {userInfo?.limited_order?.price}</span> bạn vui lòng liên hệ CSKH
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="info">
        {t('commission_percent')}: {userInfo?.limited_order?.commission ? userInfo?.limited_order?.commission : dataLevel?.commission_percent} %
        </div>
        <div className="info">
          {t('total_order_today')}: {userInfo?.total_order}/{dataLevel?.order_quantity_per_day}
        </div>
        {/* <div className="info">
          {t('profit')}:
          <span className="content">
            $ {formatPrice(data?.meta?.commission)}
          </span>
        </div>
        <div className="info">
          {t('total_refund')}:
          <span className="content">
            {' '}
            $ {formatPrice(data?.meta?.value + data?.meta?.commission)}
          </span>
        </div> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <S.StartBtn
            type="primary"
            size="large"
            onClick={() => {
              handleClickBtn()
            }}
          >
            {t('send_order')}
          </S.StartBtn>
        </div>
      </S.BillContainer>
    </S.WrapContainer>
  )
}
export default BillOrder
