const images = {
  img_background: require('./img_background.jpg').default,
  logo_web: require('./logo_web.png'),
  banner1: require('./banner6.jpg'),
  banner2: require('./banner7.jpg'),
  banner3: require('./banner8.jpg'),
  banner4: require('./banner4.png'),
  banner5: require('./banner5.png'),
  img_countdown: require('./img_countdown.gif'),
  bg: require('./bg1.jpg'),
  logo: require('./logo.png'),
  avt_placeholder: require('./avt_placeholder.png'),
  notify: require('./notify.jpg'),
  visa_img: require('./visa_img.gif'),
}
export default images
